import React from 'react';
import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';

import GlobalStyle from 'styles/global';
import theme from 'config/theme';
import Routes from 'routes';
import { AuthProvider } from 'contexts/auth';

function App() {
  return (
    <AuthProvider>
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <Routes />
              <GlobalStyle />
              <ToastContainer autoClose={3000} />
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </AuthProvider>
  );
}

export default App;

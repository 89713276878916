import styled, { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.min.css';

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
    margin: 0;
  }
  

  html {
    word-break: normal;
  }

  .width-100 {
    width: 100% !important;
  }


  ${({ theme }) => theme.breakpoints.down('sm')} {
  #menu{
    padding: 0 20px !important;
  }
  }

  html, body, #root {
    height: 100%;
    margin: 0;
  }
  html {
    word-break: normal;
  }
  #root {
    display: flex;
    flex-direction: column;
  }
  #menu{
    background: #554e9c;
  }
  #rodape{
    background-image: url('penas.jfif');
  }
  #rodape div{
    height: 20px;
  }
  #mobile-menu button span svg{
    color: #fff !important;
  }
  body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
  .MuiFormControl-marginNormal {
    margin-top: 10px;
  }
  .firebase-emulator-warning {
    display: none;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  button {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    padding: 0;
    color: inherit;
    background: none;
    border: none;
    cursor: pointer;
  }
  .list-item-evento:hover{
    background: #f1f1f1;
    transition: background 0.5s ease-in-out;
  }
`;

export const FormContainer = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
`;

export default GlobalStyle;

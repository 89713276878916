import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    h2: {
      fontWeight: 500,
    },
  },

  overrides: {
    MUIDataTableSearch: {
      searchText: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    MUIDataTableHeadCell: {
      sortAction: {
        alignItems: 'center',
      },
    },
  },
  palette: {
    primary: {
      main: '#554e9c',
      dark: '#f8ca02', // hover
      contrastText: '#fff',
    },
  },
});

export default theme;

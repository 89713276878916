import { SwipeableDrawer } from '@material-ui/core';
import styled from 'styled-components';

export const StyledDrawer = styled(SwipeableDrawer)`
  .MuiPaper-root {
    width: 200px;
    background-color: #554e9c;
    color: #f6f5f6;
  }
`;

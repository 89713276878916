import Centered from 'components/Centered';
import { login, useAuth } from 'contexts/auth';
import LoginForm from 'forms/LoginForm';
import Toti from 'images/logo.jpeg';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormContainer } from 'styles/global';

function Login() {
  const history = useHistory();
  const { user } = useAuth();

  const onSubmit = async values => {
    try {
      await login(values);
      if (user?.contador) {
        history.push('filtrar-eventos');
      } else {
        history.push('/');
      }
    } catch (error) {
      toast.error(
        'Não foi possível realizar o login, confira as credenciais informadas.',
      );
    }
  };

  return (
    <Centered column>
      <img
        draggable={false}
        style={{ width: 500, marginBottom: 20 }}
        src={Toti}
        alt="Toti Agência"
      />
      <FormContainer>
        <LoginForm onSubmit={onSubmit} />
      </FormContainer>
    </Centered>
  );
}

export default Login;

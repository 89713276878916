import { IconButton, List, ListItem, ListItemText } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { useAuth } from 'contexts/auth';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { StyledDrawer } from './styles';

function MobileDrawer({
  open = false,
  menuItems = [],
  onClose = () => {},
  onOpen = () => {},
}) {
  const { signed, user, signOut } = useAuth();
  return (
    <>
      <IconButton onClick={onOpen} id="hamb-icon">
        <Menu color="primary" />
      </IconButton>
      <StyledDrawer
        disableBackdropTransition
        onClose={onClose}
        anchor="right"
        open={open}
        onOpen={onOpen}
      >
        <List>
          {menuItems.map(item => (
            <RouterLink to={item.path} key={item.path}>
              <ListItem button>
                <ListItemText primary={item.title} />
              </ListItem>
            </RouterLink>
          ))}
          {user.clevel && (
            <RouterLink to="/filtrar-toti-numeros">
              <ListItem button>
                <ListItemText primary="Toti em Números" />
              </ListItem>
            </RouterLink>
          )}
          {!signed && (
            <RouterLink to="/admin">
              <ListItem button>
                {' '}
                <ListItemText primary="Login" />
              </ListItem>
            </RouterLink>
          )}
          {signed && (
            <RouterLink onClick={signOut} to="/">
              <ListItem button>
                <ListItemText primary="Sair" />
              </ListItem>
            </RouterLink>
          )}
        </List>
      </StyledDrawer>
    </>
  );
}

export default MobileDrawer;
